import {
  cloneDeep, assign, map,
  find, filter, isEqual,
  reduce, forEach, omit,
  split, includes, has,
  get,
  trim,
} from 'lodash';
import eventHelpers from './event';
import marketMapper from './market-mapper';
import sportIds from '@/services/helpers/sports';

const {
  BASKETBALL_ID,
  FOOTBALL_ID,
  HOCKEY_ID,
  BASEBALL_ID,
} = sportIds;

export const PLAYER_PROPS_LOWER_LIMIT = 0;
export const PLAYER_PROPS_UPPER_LIMIT = 1;

const UP_LOW_CUSTOM_VALUES = {
  rushLoad: {
    lowerLimit: 0,
    upperLimit: 1.5,
  },
  qbLoad: {
    lowerLimit: 0,
    upperLimit: 1.5,
  },
  time: {
    lowerLimit: 0,
    upperLimit: 48,
  },
  playerPositionIndex: {
    lowerLimit: 1,
    upperLimit: 999,
  },
  hitterParams: {
    lineupNumber: {
      lowerLimit: 1,
      upperLimit: 50,
    },
    runAverage: {
      lowerLimit: 0,
      upperLimit: 100,
    },
    runSupport: {
      lowerLimit: 0,
      upperLimit: 100,
    },
  },
  pitcherParams: {
    reliefPitcherOrder: {
      lowerLimit: 1,
      upperLimit: 50,
    },
    maxPitchCount: {
      lowerLimit: 0,
      upperLimit: 500,
    },
  },
  quarterbackParams: {
    passingRunningRatio: {
      lowerLimit: 0.47,
      upperLimit: 0.67,
    },
    passingTouchdownFactor: {
      lowerLimit: -1,
      upperLimit: 1,
    },
    passingCompletionFactor: {
      lowerLimit: -1,
      upperLimit: 1,
    },
    passingYardsFactor: {
      lowerLimit: 0,
      upperLimit: 2,
    },
  },
};
const PP_INCREASE_DECREASE_DEFAULT = 0.01;
const PP_INCREASE_DECREASE_VALUES = {
  // BASKETBALL
  threes: 0.005,
  rebounds: 0.005,
  time: 1,
};

export const formatPlayerName = (playerName) => {
  const splittedName = split(playerName, ', ');
  if (splittedName.length < 2) return playerName;

  return `${splittedName[1]} ${splittedName[0]}`;
};

export const formatPlayerFirstName = (playerName) => {
  // eslint-disable-next-line no-unused-vars
  const [lastName, firstName] = split(playerName, ',');
  return trim(firstName);
};

export const formatPlayerLastName = (playerName) => {
  const [lastName] = split(playerName, ',');
  return trim(lastName);
};

export const mapPlayerSetupData = (event) => {
  const eventDetails = eventHelpers.findEventDetails(event);
  const eventData = {
    ...(eventDetails || {}),
    eventId: event.eventId || '',
    eventName: event.eventName || '',
    startsAt: event.startsAt || '',
    isUSAView: event.isUsaView || false,
    matchState: event.matchState || 'N_A',
    sportId: event.sportId,
    sport: event.sport && {
      id: event?.sport?.sportId,
      name: event?.sport?.sportName,
      label: event?.sport?.sportLabel,
    },
    competitionType: eventDetails?.competitionType || '',
    period: eventDetails?.period || 'N_A',
    isSuspended: !!event?.operatorEventSuspensionsByEventId?.nodes?.length,
    isPricesApproved: !!event?.eventReadiness?.isApproved,
  };
  const teamA = () => {
    const label = eventData.isUSAView ? 'Away' : 'Home';
    const score = eventData.isUSAView ? eventData.awayScore : eventData.homeScore;
    const team = eventData.isUSAView ? event.competitors?.[1] : event.competitors?.[0];
    const lineup = eventData.isUSAView ? eventData.awayLineup : eventData.homeLineup;
    // For baseball
    const teamABatterIds = eventData?.teamABatterIds;
    const teamAPitcherIds = eventData?.teamAPitcherIds;
    return {
      ...team,
      label,
      score,
      lineup,
      teamABatterIds,
      teamAPitcherIds,
    };
  };
  const teamB = () => {
    const label = eventData.isUSAView ? 'Home' : 'Away';
    const score = eventData.isUSAView ? eventData.homeScore : eventData.awayScore;
    const team = eventData.isUSAView ? event.competitors?.[0] : event.competitors?.[1];
    const lineup = eventData.isUSAView ? eventData.homeLineup : eventData.awayLineup;
    // For baseball
    const teamBBatterIds = eventData?.teamBBatterIds;
    const teamBPitcherIds = eventData?.teamBPitcherIds;
    return {
      ...team,
      label,
      score,
      lineup,
      teamBBatterIds,
      teamBPitcherIds,
    };
  };
  return {
    ...eventData,
    teamA: teamA(),
    teamB: teamB(),
  };
};

export const mapUpdatedPlayerSetupData = (data, payload) => {
  const updatedPlayerSetupData = cloneDeep(data);
  const { teamA } = updatedPlayerSetupData;
  const { teamB } = updatedPlayerSetupData;

  teamA.score = updatedPlayerSetupData.isUSAView ? payload.state.awayScore : payload.state.homeScore;
  teamB.score = updatedPlayerSetupData.isUSAView ? payload.state.homeScore : payload.state.awayScore;
  teamA.lineup = updatedPlayerSetupData.isUSAView ? payload.competitors.awayTeam.lineup : payload.competitors.homeTeam.lineup;
  teamB.lineup = updatedPlayerSetupData.isUSAView ? payload.competitors.homeTeam.lineup : payload.competitors.awayTeam.lineup;

  assign(
    updatedPlayerSetupData,
    {
      ...updatedPlayerSetupData,
      teamA,
      teamB,
      period: payload.state.currentClock?.period ?? payload.state?.period,
      secondsLeftInPeriod: payload.state.currentClock?.secondsLeftInPeriod,
      gameTimeElapsedInPeriod: payload.state?.gameTimeElapsedInPeriod,
      teamInPossession: payload.state.teamInPossession ?? payload.state?.inPossession,
      lastTeamToScore: payload.state.lastTeamToScore,
    },
  );

  return updatedPlayerSetupData;
};

const getPlayerSetupNumberValue = (val) => {
  if (typeof val === 'number') return val;
  return val || 0;
};

export const footballPlayerParamsSetup = (data = {}) => {
  const {
    playerId, lineup, disabled, defensivePlayerParams,
    isKicker, isOffensiveNonQB, isQuarterback,
    nonQuarterbackParams, quarterbackParams,
    playerIndex, marketCodeDetails, side, playerPositionIndex,
  } = data;
  const playerMarketsLength = marketCodeDetails?.length;
  const suspendedMarketsLength = filter(marketCodeDetails, (market) => market.suspended)?.length || 0;
  const lockedMarketsLength = filter(marketCodeDetails, (market) => market.isLocked)?.length || 0;
  const playerParams = {
    playerParams: {
      playerId,
      playerIndex,
      sport: 'football',
      side,
      isDefensive: false,
      isKicker: isKicker || false,
      isOffensiveNonQB: isOffensiveNonQB || false,
      isQuarterback: isQuarterback || false,
      defensivePlayerParams: defensivePlayerParams || {},
      nonQuarterbackParams: {
        receiveLongNoScore: getPlayerSetupNumberValue(nonQuarterbackParams?.receiveLongNoScore),
        receiveLongScore: getPlayerSetupNumberValue(nonQuarterbackParams?.receiveLongScore),
        receiveShortNoScore: getPlayerSetupNumberValue(nonQuarterbackParams?.receiveShortNoScore),
        receiveShortScore: getPlayerSetupNumberValue(nonQuarterbackParams?.receiveShortScore),
        runLongNoScore: getPlayerSetupNumberValue(nonQuarterbackParams?.runLongNoScore),
        runLongScore: getPlayerSetupNumberValue(nonQuarterbackParams?.runLongScore),
        runShortNoScore: getPlayerSetupNumberValue(nonQuarterbackParams?.runShortNoScore),
        runShortScore: getPlayerSetupNumberValue(nonQuarterbackParams?.runShortScore),
      },
      quarterbackParams: {
        interceptionTurnoverRatio: getPlayerSetupNumberValue(quarterbackParams?.interceptionTurnoverRatio),
        passingCompletionFactor: getPlayerSetupNumberValue(quarterbackParams?.passingCompletionFactor),
        passingRunningRatio: getPlayerSetupNumberValue(quarterbackParams?.passingRunningRatio),
        passingTouchdownFactor: getPlayerSetupNumberValue(quarterbackParams?.passingTouchdownFactor),
        passingYardsFactor: getPlayerSetupNumberValue(quarterbackParams?.passingYardsFactor),
        runLongNoScore: getPlayerSetupNumberValue(quarterbackParams?.runLongNoScore),
        runLongScore: getPlayerSetupNumberValue(quarterbackParams?.runLongScore),
        runShortNoScore: getPlayerSetupNumberValue(quarterbackParams?.runShortNoScore),
        runShortScore: getPlayerSetupNumberValue(quarterbackParams?.runShortScore),
      },
    },
    lineup: lineup || false,
    marketCodeDetails: marketCodeDetails || [],
    isSuspended: !!(playerMarketsLength && suspendedMarketsLength && suspendedMarketsLength + lockedMarketsLength === playerMarketsLength),
    playerPositionIndex,
    disabled: disabled || false,
  };

  if (isQuarterback) {
    playerParams.playerParams.isKicker = false;
    playerParams.playerParams.isOffensiveNonQB = false;
    if (has(playerParams.playerParams, 'nonQuarterbackParams')) {
      playerParams.playerParams.nonQuarterbackParams = null;
    }
  }
  if (isOffensiveNonQB) {
    playerParams.playerParams.isKicker = false;
    playerParams.playerParams.isQuarterback = false;
    if (has(playerParams.playerParams, 'nonQuarterbackParams')) {
      playerParams.playerParams.quarterbackParams = null;
    }
  }
  if (isKicker) {
    playerParams.playerParams.isOffensiveNonQB = false;
    playerParams.playerParams.isQuarterback = false;
    if (has(playerParams.playerParams, 'nonQuarterbackParams')) {
      playerParams.playerParams.nonQuarterbackParams = null;
    }
    if (has(playerParams.playerParams, 'nonQuarterbackParams')) {
      playerParams.playerParams.quarterbackParams = null;
    }
  }

  return playerParams;
};

export const priceOnDemandFootballPlayerParamsSetup = (data = {}) => {
  const {
    playerId, lineup, disabled, defensivePlayerParams,
    isKicker, isOffensiveNonQB, isQuarterback,
    nonQuarterbackParams, quarterbackParams,
    playerIndex, marketCodeDetails, side,
  } = data;
  const playerParams = {
    playerId,
    playerIndex,
    sport: 'football',
    side,
    marketCodes: map(marketCodeDetails, (market) => market.marketCode),
    disabled: disabled || false,
    lineup: lineup || false,
    isDefensive: false,
    isKicker: isKicker || false,
    isOffensiveNonQB: isOffensiveNonQB || false,
    isQuarterback: isQuarterback || false,
    defensivePlayerParams: defensivePlayerParams || {},
    nonQuarterbackParams: {
      receiveLongNoScore: getPlayerSetupNumberValue(nonQuarterbackParams?.receiveLongNoScore),
      receiveLongScore: getPlayerSetupNumberValue(nonQuarterbackParams?.receiveLongScore),
      receiveShortNoScore: getPlayerSetupNumberValue(nonQuarterbackParams?.receiveShortNoScore),
      receiveShortScore: getPlayerSetupNumberValue(nonQuarterbackParams?.receiveShortScore),
      runLongNoScore: getPlayerSetupNumberValue(nonQuarterbackParams?.runLongNoScore),
      runLongScore: getPlayerSetupNumberValue(nonQuarterbackParams?.runLongScore),
      runShortNoScore: getPlayerSetupNumberValue(nonQuarterbackParams?.runShortNoScore),
      runShortScore: getPlayerSetupNumberValue(nonQuarterbackParams?.runShortScore),
    },
    quarterbackParams: {
      interceptionTurnoverRatio: getPlayerSetupNumberValue(quarterbackParams?.interceptionTurnoverRatio),
      passingCompletionFactor: getPlayerSetupNumberValue(quarterbackParams?.passingCompletionFactor),
      passingRunningRatio: getPlayerSetupNumberValue(quarterbackParams?.passingRunningRatio),
      passingTouchdownFactor: getPlayerSetupNumberValue(quarterbackParams?.passingTouchdownFactor),
      passingYardsFactor: getPlayerSetupNumberValue(quarterbackParams?.passingYardsFactor),
      runLongNoScore: getPlayerSetupNumberValue(quarterbackParams?.runLongNoScore),
      runLongScore: getPlayerSetupNumberValue(quarterbackParams?.runLongScore),
      runShortNoScore: getPlayerSetupNumberValue(quarterbackParams?.runShortNoScore),
      runShortScore: getPlayerSetupNumberValue(quarterbackParams?.runShortScore),
    },
  };
  if (isQuarterback) {
    playerParams.isKicker = false;
    playerParams.isOffensiveNonQB = false;
    if (has(playerParams, 'nonQuarterbackParams')) {
      playerParams.nonQuarterbackParams = null;
    }
  }
  if (isOffensiveNonQB) {
    playerParams.isQuarterback = false;
    playerParams.isKicker = false;
    if (has(playerParams, 'nonQuarterbackParams')) {
      playerParams.quarterbackParams = null;
    }
  }
  if (isKicker) {
    playerParams.isQuarterback = false;
    playerParams.isOffensiveNonQB = false;
    if (has(playerParams, 'nonQuarterbackParams')) {
      playerParams.nonQuarterbackParams = null;
    }
    if (has(playerParams, 'nonQuarterbackParams')) {
      playerParams.quarterbackParams = null;
    }
  }

  return playerParams;
};

export const basketballPlayerParamsSetup = (data = {}) => {
  const {
    playerId, lineup, star, time,
    rebounds, assists, points,
    threes, playerIndex, marketCodeDetails, side, playerPositionIndex,
    disabled,
  } = data;
  const playerMarketsLength = marketCodeDetails?.length;
  const suspendedMarketsLength = filter(marketCodeDetails, (market) => market.suspended)?.length || 0;
  const lockedMarketsLength = filter(marketCodeDetails, (market) => market.isLocked)?.length || 0;
  const playerParams = {
    playerParams: {
      playerId,
      playerIndex,
      star: star || false,
      time: getPlayerSetupNumberValue(time),
      rebounds: getPlayerSetupNumberValue(rebounds),
      assists: getPlayerSetupNumberValue(assists),
      points: getPlayerSetupNumberValue(points),
      threes: getPlayerSetupNumberValue(threes),
      sport: 'basketball',
      side,
    },
    lineup: lineup || false,
    disabled: disabled || false,
    marketCodeDetails: marketCodeDetails || [],
    isSuspended: playerMarketsLength && suspendedMarketsLength && suspendedMarketsLength + lockedMarketsLength === playerMarketsLength,
    playerPositionIndex,
  };

  return playerParams;
};

export const priceOnDemandBasketballPlayerParamsSetup = (data = {}) => {
  const {
    playerId, lineup, star, time,
    rebounds, assists, points,
    threes, playerIndex, marketCodeDetails,
    side, sport, disabled,
  } = data;
  const playerParams = {
    playerId,
    playerIndex,
    star: star || false,
    time: getPlayerSetupNumberValue(time),
    rebounds: getPlayerSetupNumberValue(rebounds),
    assists: getPlayerSetupNumberValue(assists),
    points: getPlayerSetupNumberValue(points),
    threes: getPlayerSetupNumberValue(threes),
    lineup: lineup || false,
    disabled: disabled || false,
    side,
    sport,
    marketCodes: map(marketCodeDetails, (market) => market.marketCode),
  };

  return playerParams;
};

export const hockeyPlayerParamsSetup = (data = {}) => {
  const {
    playerId, lineup, goals, assists,
    playerIndex, marketCodeDetails, star, side,
    playerPositionIndex, disabled,
  } = data;
  const playerMarketsLength = marketCodeDetails?.length;
  const suspendedMarketsLength = filter(marketCodeDetails, (market) => market.suspended)?.length || 0;
  const lockedMarketsLength = filter(marketCodeDetails, (market) => market.isLocked)?.length || 0;
  const playerParams = {
    playerParams: {
      playerId,
      playerIndex,
      star: star || false,
      goals: getPlayerSetupNumberValue(goals),
      assists: getPlayerSetupNumberValue(assists),
      shotsOnGoal: 0.01, // Hardcoded value for now
      sport: 'hockey',
      side,
    },
    lineup: lineup || false,
    disabled: disabled || false,
    marketCodeDetails: marketCodeDetails || [],
    isSuspended: playerMarketsLength && suspendedMarketsLength && suspendedMarketsLength + lockedMarketsLength === playerMarketsLength,
    playerPositionIndex,
  };

  return playerParams;
};

export const priceOnDemandHockeyPlayerParamsSetup = (data = {}) => {
  const {
    playerId, lineup, goals, assists,
    playerIndex, marketCodeDetails, star, side,
    disabled,
  } = data;
  const playerParams = {
    playerId,
    playerIndex,
    star: star || false,
    goals: getPlayerSetupNumberValue(goals),
    assists: getPlayerSetupNumberValue(assists),
    shotsOnGoal: 0.01, // Hardcoded value for now
    sport: 'hockey',
    side,
    lineup: lineup || false,
    disabled: disabled || false,
    marketCodes: map(marketCodeDetails, (market) => market.marketCode),
  };
  return playerParams;
};

export const baseballPlayerParamsSetup = (data = {}) => {
  const {
    playerId, lineup,
    playerIndex, marketCodeDetails, side,
    hitterParams, pitcherParams, isPitcher, isHitter,
    playerPositionIndex, disabled,
  } = data;
  const playerMarketsLength = marketCodeDetails?.length;
  const suspendedMarketsLength = filter(marketCodeDetails, (market) => market.suspended)?.length || 0;
  const lockedMarketsLength = filter(marketCodeDetails, (market) => market.isLocked)?.length || 0;
  const playerParams = {
    playerParams: {
      playerId,
      playerIndex,
      sport: 'baseball',
      side,
      hitterParams: {
        buntProb: getPlayerSetupNumberValue(hitterParams?.buntProb),
        doublePlayProb: getPlayerSetupNumberValue(hitterParams?.doublePlayProb),
        isCatcher: hitterParams?.isCatcher || false,
        lineupNumber: getPlayerSetupNumberValue(hitterParams?.lineupNumber),
        runAverage: getPlayerSetupNumberValue(hitterParams?.runAverage),
        runSupport: getPlayerSetupNumberValue(hitterParams?.runSupport),
        handedness: hitterParams?.handedness || 'RIGHT',
        againstLeftHandedEff: getPlayerSetupNumberValue(hitterParams?.againstLeftHandedEff),
        againstRightHandedEff: getPlayerSetupNumberValue(hitterParams?.againstRightHandedEff),
        leftHandedProjection: {
          walkProb: getPlayerSetupNumberValue(hitterParams?.leftHandedProjection?.walkProb),
          strikeOutProb: getPlayerSetupNumberValue(hitterParams?.leftHandedProjection?.strikeOutProb),
          singleProb: getPlayerSetupNumberValue(hitterParams?.leftHandedProjection?.singleProb),
          doubleOrTripleProb: getPlayerSetupNumberValue(hitterParams?.leftHandedProjection?.doubleOrTripleProb),
          homeRunProb: getPlayerSetupNumberValue(hitterParams?.leftHandedProjection?.homeRunProb),
        },
        rightHandedProjection: {
          walkProb: getPlayerSetupNumberValue(hitterParams?.rightHandedProjection?.walkProb),
          strikeOutProb: getPlayerSetupNumberValue(hitterParams?.rightHandedProjection?.strikeOutProb),
          singleProb: getPlayerSetupNumberValue(hitterParams?.rightHandedProjection?.singleProb),
          doubleOrTripleProb: getPlayerSetupNumberValue(hitterParams?.rightHandedProjection?.doubleOrTripleProb),
          homeRunProb: getPlayerSetupNumberValue(hitterParams?.rightHandedProjection?.homeRunProb),
        },
      },
      pitcherParams: {
        reliefPitcherOrder: getPlayerSetupNumberValue(pitcherParams?.reliefPitcherOrder),
        maxPitchCount: getPlayerSetupNumberValue(pitcherParams?.maxPitchCount),
        isNextPitcher: pitcherParams?.isNextPitcher || false,
        isCurrentPitcher: pitcherParams?.isCurrentPitcher || false,
        hittersSeen: pitcherParams?.hittersSeen || [],
        handedness: pitcherParams?.handedness || 'RIGHT',
        noCatcherDefinedProjection: {
          leftHandedProjection: {
            walkProb: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.leftHandedProjection?.walkProb),
            strikeOutProb: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.leftHandedProjection?.strikeOutProb),
            singleProb: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.leftHandedProjection?.singleProb),
            doubleOrTripleProb: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.leftHandedProjection?.doubleOrTripleProb),
            homeRunProb: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.leftHandedProjection?.homeRunProb),
          },
          rightHandedProjection: {
            walkProb: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.rightHandedProjection?.walkProb),
            strikeOutProb: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.rightHandedProjection?.strikeOutProb),
            singleProb: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.rightHandedProjection?.singleProb),
            doubleOrTripleProb: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.rightHandedProjection?.doubleOrTripleProb),
            homeRunProb: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.rightHandedProjection?.homeRunProb),
          },
          pitchesPerBatToLefties: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.pitchesPerBatToLefties),
          pitchesPerBatToRighties: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.pitchesPerBatToRighties),
          againstLeftHandedEff: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.againstLeftHandedEff),
          againstRightHandedEff: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.againstRightHandedEff),
          doublePlayProb: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.doublePlayProb),
        },
        catcherPitcherProjections: pitcherParams?.catcherPitcherProjections || {},
      },
    },
    isPitcher,
    isHitter,
    lineup: lineup || false,
    marketCodeDetails: marketCodeDetails || [],
    isSuspended: playerMarketsLength && suspendedMarketsLength && suspendedMarketsLength + lockedMarketsLength === playerMarketsLength,
    playerPositionIndex,
    disabled: disabled || false,
  };

  if (isPitcher && !isHitter) {
    playerParams.playerParams.hitterParams = null;
  }
  if (isHitter && !isPitcher) {
    playerParams.playerParams.pitcherParams = null;
  }

  return playerParams;
};

export const priceOnDemandBaseballPlayerParamsSetup = (data = {}) => {
  const {
    playerId, marketCodeDetails,
    playerIndex, side,
    hitterParams, pitcherParams,
    isPitcher, isHitter, disabled,
    lineup,
  } = data;
  const playerParams = {
    playerId,
    playerIndex,
    sport: 'baseball',
    side,
    marketCodes: map(marketCodeDetails, (market) => market.marketCode),
    disabled: disabled || false,
    lineup: lineup || false,
    hitterParams: {
      buntProb: getPlayerSetupNumberValue(hitterParams?.buntProb),
      doublePlayProb: getPlayerSetupNumberValue(hitterParams?.doublePlayProb),
      isCatcher: hitterParams?.isCatcher || false,
      lineupNumber: getPlayerSetupNumberValue(hitterParams?.lineupNumber),
      runAverage: getPlayerSetupNumberValue(hitterParams?.runAverage),
      runSupport: getPlayerSetupNumberValue(hitterParams?.runSupport),
      handedness: hitterParams?.handedness || 'RIGHT',
      leftHandedProjection: {
        walkProb: getPlayerSetupNumberValue(hitterParams?.leftHandedProjection?.walkProb),
        strikeOutProb: getPlayerSetupNumberValue(hitterParams?.leftHandedProjection?.strikeOutProb),
        singleProb: getPlayerSetupNumberValue(hitterParams?.leftHandedProjection?.singleProb),
        doubleOrTripleProb: getPlayerSetupNumberValue(hitterParams?.leftHandedProjection?.doubleOrTripleProb),
        homeRunProb: getPlayerSetupNumberValue(hitterParams?.leftHandedProjection?.homeRunProb),
      },
      rightHandedProjection: {
        walkProb: getPlayerSetupNumberValue(hitterParams?.rightHandedProjection?.walkProb),
        strikeOutProb: getPlayerSetupNumberValue(hitterParams?.rightHandedProjection?.strikeOutProb),
        singleProb: getPlayerSetupNumberValue(hitterParams?.rightHandedProjection?.singleProb),
        doubleOrTripleProb: getPlayerSetupNumberValue(hitterParams?.rightHandedProjection?.doubleOrTripleProb),
        homeRunProb: getPlayerSetupNumberValue(hitterParams?.rightHandedProjection?.homeRunProb),
      },
    },
    pitcherParams: {
      reliefPitcherOrder: getPlayerSetupNumberValue(pitcherParams?.reliefPitcherOrder),
      maxPitchCount: getPlayerSetupNumberValue(pitcherParams?.maxPitchCount),
      isNextPitcher: pitcherParams?.isNextPitcher || false,
      isCurrentPitcher: pitcherParams?.isCurrentPitcher || false,
      hittersSeen: pitcherParams?.hittersSeen || [],
      handedness: pitcherParams?.handedness || 'RIGHT',
      noCatcherDefinedProjection: {
        leftHandedProjection: {
          walkProb: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.leftHandedProjection?.walkProb),
          strikeOutProb: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.leftHandedProjection?.strikeOutProb),
          singleProb: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.leftHandedProjection?.singleProb),
          doubleOrTripleProb: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.leftHandedProjection?.doubleOrTripleProb),
          homeRunProb: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.leftHandedProjection?.homeRunProb),
        },
        rightHandedProjection: {
          walkProb: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.rightHandedProjection?.walkProb),
          strikeOutProb: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.rightHandedProjection?.strikeOutProb),
          singleProb: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.rightHandedProjection?.singleProb),
          doubleOrTripleProb: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.rightHandedProjection?.doubleOrTripleProb),
          homeRunProb: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.rightHandedProjection?.homeRunProb),
        },
        pitchesPerBatToLefties: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.pitchesPerBatToLefties),
        pitchesPerBatToRighties: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.pitchesPerBatToRighties),
        doublePlayProb: getPlayerSetupNumberValue(pitcherParams?.noCatcherDefinedProjection?.doublePlayProb),
      },
      catcherPitcherProjections: pitcherParams?.catcherPitcherProjections || {},
    },
  };

  if (isPitcher && !isHitter) {
    playerParams.hitterParams = null;
  }
  if (isHitter && !isPitcher) {
    playerParams.pitcherParams = null;
  }

  return playerParams;
};

export const mapPlayerSetupPayloadData = (playerSetupData, playerSetupTableData, selectedParamSource = 'MANUAL') => {
  const { isUSAView, sportId } = playerSetupData;
  const {
    teamA,
    teamB,
  } = playerSetupTableData;

  const homeTeam = {};
  const awayTeam = {};

  const firstTeam = isUSAView ? teamB : teamA;
  const secondTeam = isUSAView ? teamA : teamB;
  switch (sportId) {
  case FOOTBALL_ID:
    homeTeam.playersParams = map(firstTeam, (playerData) => footballPlayerParamsSetup(playerData));
    awayTeam.playersParams = map(secondTeam, (playerData) => footballPlayerParamsSetup(playerData));
    break;
  case BASKETBALL_ID:
    homeTeam.playersParams = map(firstTeam, (playerData) => basketballPlayerParamsSetup(playerData));
    awayTeam.playersParams = map(secondTeam, (playerData) => basketballPlayerParamsSetup(playerData));
    break;
  case HOCKEY_ID:
    homeTeam.playersParams = map(firstTeam, (playerData) => hockeyPlayerParamsSetup(playerData));
    awayTeam.playersParams = map(secondTeam, (playerData) => hockeyPlayerParamsSetup(playerData));
    break;

  case BASEBALL_ID:
    homeTeam.playersParams = map(firstTeam, (playerData) => baseballPlayerParamsSetup(playerData));
    awayTeam.playersParams = map(secondTeam, (playerData) => baseballPlayerParamsSetup(playerData));
    break;
  default:
    break;
  }
  return {
    homeTeam,
    awayTeam,
    selectedParamSource: selectedParamSource || 'MANUAL',
  };
};
const FOOTBALL_PLAYER_REQUIRED_FIELDS = ['nonQuarterbackParams.receiveLongNoScore', 'nonQuarterbackParams.receiveLongScore', 'nonQuarterbackParams.receiveShortNoScore', 'nonQuarterbackParams.receiveShortScore', 'nonQuarterbackParams.runLongNoScore', 'nonQuarterbackParams.runLongScore', 'nonQuarterbackParams.runShortNoScore', 'nonQuarterbackParams.runShortScore', 'quarterbackParams.interceptionTurnoverRatio', 'quarterbackParams.passingCompletionFactor', 'quarterbackParams.passingRunningRatio', 'quarterbackParams.passingTouchdownFactor', 'quarterbackParams.passingYardsFactor', 'quarterbackParams.runLongNoScore', 'quarterbackParams.runLongScore', 'quarterbackParams.runShortNoScore', 'quarterbackParams.runShortScore'];
const BASKETBALL_PLAYER_REQUIRED_FIELDS = ['time', 'rebounds', 'assists', 'points', 'threes', 'playerPositionIndex'];
const HOCKEY_PLAYER_REQUIRED_FIELDS = ['goals', 'assists', 'playerPositionIndex'];
const BASEBALL_PLAYER_REQUIRED_FIELDS = ['hitterParams.lineupNumber', 'pitcherParams.maxPitchCount', 'pitcherParams.reliefPitcherOrder'];

export const requiredFieldsBySport = (sportId) => {
  let requiredFields;
  switch (sportId) {
  case FOOTBALL_ID: requiredFields = FOOTBALL_PLAYER_REQUIRED_FIELDS; break;
  case BASKETBALL_ID: requiredFields = BASKETBALL_PLAYER_REQUIRED_FIELDS; break;
  case HOCKEY_ID: requiredFields = HOCKEY_PLAYER_REQUIRED_FIELDS; break;
  case BASEBALL_ID: requiredFields = BASEBALL_PLAYER_REQUIRED_FIELDS; break;
  default: break;
  }
  return requiredFields;
};

export const getLowerLimit = (field) => get(UP_LOW_CUSTOM_VALUES, field)?.lowerLimit || PLAYER_PROPS_LOWER_LIMIT;
export const getUpperLimit = (field) => get(UP_LOW_CUSTOM_VALUES, field)?.upperLimit || PLAYER_PROPS_UPPER_LIMIT;

export const getPlayerPropIncreaseDecreaseValue = (field) => PP_INCREASE_DECREASE_VALUES[field] || PP_INCREASE_DECREASE_DEFAULT;

export const checkIfRequiredFieldsEnteredByRow = (requiredFields, player) => {
  let allRequiredFieldsEntered = true;
  forEach(requiredFields, (field) => {
    if (
      (!get(player, field) && get(player, field) !== 0)
      || (
        (get(player, field) < getLowerLimit(field))
        || (get(player, field) > getUpperLimit(field))
      )
      || typeof get(player, field) !== 'number') {
      allRequiredFieldsEntered = false;
      return false;
    }
    return true;
  });
  return allRequiredFieldsEntered;
};

export const requiredFieldsByPlayer = (player, sportId, sportRequiredFields) => {
  let requiredFields;
  if (sportId === BASEBALL_ID) {
    const { isPitcher } = player;
    const { isHitter } = player;
    if (isPitcher && isHitter) {
      requiredFields = sportRequiredFields;
    } else if (isPitcher) {
      requiredFields = filter(sportRequiredFields, (field) => includes(field, 'pitcherParams'));
    } else if (isHitter) {
      requiredFields = filter(sportRequiredFields, (field) => includes(field, 'hitterParams'));
    }
    // Adding playerPositionIndex as required field for both pitcher and hitter.
    requiredFields = [
      ...requiredFields,
      'playerPositionIndex',
    ];
  } else if (sportId === FOOTBALL_ID) {
    const { isQuarterback, isOffensiveNonQB } = player;
    if (isQuarterback) {
      requiredFields = filter(sportRequiredFields, (field) => includes(field, 'quarterbackParams'));
    } else if (isOffensiveNonQB) {
      requiredFields = filter(sportRequiredFields, (field) => includes(field, 'nonQuarterbackParams'));
    }
    // Adding playerPositionIndex as required field for both quarterback and offensiveNonQB.
    requiredFields = [
      ...(requiredFields || []),
      'playerPositionIndex',
    ];
  } else {
    requiredFields = sportRequiredFields;
  }
  return requiredFields;
};

export const checkIfRequiredFieldsEntered = (data, sportId) => {
  if (!data.length) return true;
  if (sportId === BASEBALL_ID) {
    const numberOfCurrentPitchers = filter(data, (player) => player?.pitcherParams?.isCurrentPitcher).length;
    if (numberOfCurrentPitchers > 1) return false;
  }
  let allRequiredFieldsEntered = true;
  const requiredFields = requiredFieldsBySport(sportId);

  forEach(data, (player) => {
    const requiredFieldsByPlayerAndSport = requiredFieldsByPlayer(player, sportId, requiredFields);
    allRequiredFieldsEntered = checkIfRequiredFieldsEnteredByRow(requiredFieldsByPlayerAndSport, player);
    if (!allRequiredFieldsEntered) {
      return false;
    }
    return true;
  });
  return allRequiredFieldsEntered;
};

const mapPriceOnDemandPayloadByTeam = (teamData, sportId, isPricingPreview) => {
  let paramsBySportSetupFunction;
  const requiredFields = requiredFieldsBySport(sportId);
  switch (sportId) {
  case FOOTBALL_ID:
    paramsBySportSetupFunction = priceOnDemandFootballPlayerParamsSetup;
    break;
  case BASKETBALL_ID:
    paramsBySportSetupFunction = priceOnDemandBasketballPlayerParamsSetup;
    break;
  case HOCKEY_ID:
    paramsBySportSetupFunction = priceOnDemandHockeyPlayerParamsSetup;
    break;
  case BASEBALL_ID:
    paramsBySportSetupFunction = priceOnDemandBaseballPlayerParamsSetup;
    break;
  default:
    break;
  }
  return map(
    filter(teamData, ((player) => {
      if (isPricingPreview) {
        const requiredFieldsByPlayerAndSport = requiredFieldsByPlayer(player, sportId, requiredFields);
        return checkIfRequiredFieldsEnteredByRow(requiredFieldsByPlayerAndSport, player);
      }
      return true;
    })), (playerData) => paramsBySportSetupFunction(playerData),
  );
};

export const mapPriceOnDemandPayload = (playerSetupData, playerSetupTableData, isPricingPreview) => {
  const { sportId } = playerSetupData;
  const isUSAView = has(playerSetupData, 'isUSAView') ? playerSetupData.isUSAView : playerSetupData.isUsaView;
  const {
    teamA,
    teamB,
  } = playerSetupTableData;

  const homeTeam = {};
  const awayTeam = {};

  const firstTeam = isUSAView ? teamB : teamA;
  const secondTeam = isUSAView ? teamA : teamB;
  homeTeam.players = mapPriceOnDemandPayloadByTeam(firstTeam, sportId, isPricingPreview);
  awayTeam.players = mapPriceOnDemandPayloadByTeam(secondTeam, sportId, isPricingPreview);

  return {
    homeTeam,
    awayTeam,
  };
};

export const mapPlayerSetupTableDataResponse = (playerSetupData, playerSetupTableData, squadData) => {
  const { isUSAView } = playerSetupData;
  const { homeTeam, awayTeam } = playerSetupTableData;
  const { teamASquad, teamBSquad } = squadData;
  const homeTeamData = map(homeTeam?.playersParams, (player) => ({
    ...player?.playerParams,
    lineup: player?.lineup,
    voided: player?.voided,
    marketCodeDetails: player?.marketCodeDetails,
    // Baseball params
    isPitcher: player.isPitcher,
    isHitter: player.isHitter,
    // AF params
    isQuarterback: player?.playerParams?.isQuarterback,
    isOffensiveNonQB: player?.playerParams?.isOffensiveNonQB,
    playerPositionIndex: player?.playerPositionIndex || 1,
    disabled: player?.disabled,
  }));
  const awayTeamData = map(awayTeam?.playersParams, (player) => ({
    ...player?.playerParams,
    lineup: player?.lineup,
    voided: player?.voided,
    marketCodeDetails: player?.marketCodeDetails,
    // Baseball params
    isPitcher: player.isPitcher,
    isHitter: player.isHitter,
    // AF params
    isQuarterback: player?.playerParams?.isQuarterback,
    isOffensiveNonQB: player?.playerParams?.isOffensiveNonQB,
    playerPositionIndex: player?.playerPositionIndex || 1,
    disabled: player?.disabled,
  }));
  const data = {
    teamA: isUSAView ? awayTeamData : homeTeamData,
    teamB: isUSAView ? homeTeamData : awayTeamData,
  };

  data.teamA = map(data.teamA, (player) => ({
    ...player,
    playerLocked: true,
    playerName: find(teamASquad, { playerId: player.playerId })?.personalInfo?.name,
    marketCodeDetails: map(player.marketCodeDetails, (market) => ({
      ...market,
      marketName: marketMapper.playersMarketName(market.marketCode),
    })),
    isSuspended:
    player.marketCodeDetails.length
    && !filter(player.marketCodeDetails, (market) => !market.suspended)?.length,
  }));
  data.teamB = map(data.teamB, (player) => ({
    ...player,
    playerLocked: true,
    playerName: find(teamBSquad, { playerId: player.playerId })?.personalInfo?.name,
    marketCodeDetails: map(player.marketCodeDetails, (market) => ({
      ...market,
      marketName: marketMapper.playersMarketName(market.marketCode),
    })),
    isSuspended:
    player.marketCodeDetails.length
    && !filter(player.marketCodeDetails, (market) => !market.suspended)?.length,
  }));

  return data;
};

const omitFieldsFromArrayOfObjects = (data, fields) => map(data, (item) => omit(item, fields));

const isPlayersMarketsChanged = (newData, originalData) => {
  const newDataCopy = cloneDeep(newData);
  const originalDataCopy = cloneDeep(originalData);

  let playerMarketsChanged = false;

  forEach(newDataCopy.teamA, (player) => {
    const originalMarketsLength = find(originalDataCopy.teamA, { playerId: player.playerId })?.marketCodeDetails?.length;
    if (player.marketCodeDetails?.length !== originalMarketsLength) playerMarketsChanged = true;
  });

  forEach(newDataCopy.teamB, (player) => {
    const originalMarketsLength = find(originalDataCopy.teamB, { playerId: player.playerId })?.marketCodeDetails?.length;
    if (player.marketCodeDetails?.length !== originalMarketsLength) playerMarketsChanged = true;
  });

  return playerMarketsChanged;
};

export const checkIsPlayerSetupSubmitEnabled = ({
  newData, originalData, sportId,
}) => {
  const newDataCopy = cloneDeep(newData);
  const originalDataCopy = cloneDeep(originalData);
  newDataCopy.teamA = omitFieldsFromArrayOfObjects(newData.teamA, ['marketCodeDetails', 'isSuspended']);
  newDataCopy.teamB = omitFieldsFromArrayOfObjects(newData.teamB, ['marketCodeDetails', 'isSuspended']);
  originalDataCopy.teamA = omitFieldsFromArrayOfObjects(originalDataCopy.teamA, ['marketCodeDetails', 'isSuspended']);
  originalDataCopy.teamB = omitFieldsFromArrayOfObjects(originalDataCopy.teamB, ['marketCodeDetails', 'isSuspended']);

  // If there are no added players submit should be disabled
  if (!newDataCopy.teamA.length && !newDataCopy.teamB.length) {
    return {
      isPlayerParamsChanged: false,
      isPlayerParamsValid: true,
    };
  }

  const requiredFieldsEntered = checkIfRequiredFieldsEntered(newDataCopy.teamA, sportId)
    && checkIfRequiredFieldsEntered(newDataCopy.teamB, sportId);

  return {
    isPlayerParamsChanged: !isEqual(newDataCopy, originalDataCopy)
    || isPlayersMarketsChanged(newData, originalData),
    isPlayerParamsValid: requiredFieldsEntered,
  };
};

export const mapMarketsByPlayerIndex = (players) => reduce(
  players,
  (allPlayers, player) => {
    const playerIndex = player?.playerIndex;
    const marketsPerPlayer = {};
    forEach(filter(player.markets, (market) => (
      market?.marketSummary?.isMainLine
      // Markets without line
      || includes(market?.marketType?.marketCode, 'SCORER')
      || includes(market?.marketType?.marketCode, 'MILESTONE')
      // Baseball markets without line
      || includes(['PLAYER_HOME_RUN', 'PLAYER_ON_BASE', 'PLAYER_ON_BASE_TWO_PLUS'], market?.marketType?.marketCode)
      // AF markets without line
      || includes(['PLAYER_TOUCHDOWN_TWO_OR_MORE', 'PLAYER_TOUCHDOWN_THREE_OR_MORE'], market?.marketType?.marketCode)
    )), (market) => {
      marketsPerPlayer[market?.marketType?.marketCode] = {
        params: market?.marketType?.params,
        selections: market?.selections,
        marketSummary: market?.marketSummary,
      };
    });
    return {
      ...allPlayers,
      [playerIndex]: marketsPerPlayer,
    };
  },
  {},
);

const mergePlayerDataWithMarketPricesByTeam = (teamData, marketsByPlayerIndex) => reduce(
  teamData,
  (allPlayers, player) => {
    const playerMarketsWithPrices = marketsByPlayerIndex[player.playerIndex];
    const playerData = { ...player };
    if (playerMarketsWithPrices) {
      playerData.marketCodeDetails = map(playerData.marketCodeDetails, (market) => {
        const marketPrices = playerMarketsWithPrices[market.marketCode] || {};
        return {
          ...market,
          ...marketPrices,
        };
      });
    }
    return [
      ...allPlayers,
      ...[playerData],
    ];
  },
  [],
);

export const mergePlayerDataWithMarketPrices = (playersTableData, marketsByPlayerIndex) => {
  if (!marketsByPlayerIndex) return playersTableData;
  const data = { ...playersTableData };

  data.teamA = mergePlayerDataWithMarketPricesByTeam(data.teamA, marketsByPlayerIndex);
  data.teamB = mergePlayerDataWithMarketPricesByTeam(data.teamB, marketsByPlayerIndex);

  return data;
};

export const getUpdatedMarketCodeDetails = (updatedMarketsByPlayerIndex, playerIndex, existingMarkets) => {
  const updatedMarkets = updatedMarketsByPlayerIndex[playerIndex];
  const updatedMarketCodeDetails = [];
  if (!updatedMarkets) return existingMarkets;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(updatedMarkets)) {
    updatedMarketCodeDetails.push({
      ...(find(existingMarkets, { marketCode: key }) || {}),
      marketCode: key,
      marketName: marketMapper.playersMarketName(key),
      suspended: updatedMarkets && (value === 'NOT_OFFERED' || value === 'VOIDED'),
      voided: updatedMarkets && value === 'VOIDED',
    });
  }
  return [
    ...updatedMarketCodeDetails,
    ...filter(existingMarkets, (market) => !includes(
      map(updatedMarketCodeDetails, (updatedMarket) => updatedMarket.marketCode), market.marketCode,
    )),
  ];
};

const updatePlayerPropsDataByTeam = (teamData, updatedPlayerProps, updatedMarkets, updatedIndexes) => reduce(
  teamData,
  (allPlayers, player) => {
    const playerToUpdate = cloneDeep(player);

    const updatedPlayer = find(updatedPlayerProps, { playerIndex: player.playerIndex });
    const updatedParams = updatedPlayer || {};
    const updatedPlayerPositionIndex = updatedIndexes[player.playerIndex];

    playerToUpdate.marketCodeDetails = getUpdatedMarketCodeDetails(updatedMarkets, playerToUpdate.playerIndex, playerToUpdate.marketCodeDetails);
    const playerMarketsLength = playerToUpdate?.marketCodeDetails?.length;
    const suspendedMarketsLength = filter(playerToUpdate?.marketCodeDetails, (market) => market.suspended)?.length || 0;
    const lockedMarketsLength = filter(playerToUpdate?.marketCodeDetails, (market) => market.isLocked)?.length || 0;
    playerToUpdate.isSuspended = playerMarketsLength && suspendedMarketsLength && suspendedMarketsLength + lockedMarketsLength === playerMarketsLength;
    return [
      ...allPlayers,
      {
        ...playerToUpdate,
        ...updatedParams,
        playerPositionIndex: updatedPlayerPositionIndex,
      },
    ];
  },
  [],
);

const addNewPlayerDataParamsManager = (existingDataByTeam, teamId, newData, squadData, updatedMarketsByPlayerIndex, updatedIndexes) => {
  const newPlayersToAdd = [];
  const existingPlayerIds = map(existingDataByTeam, (player) => player.playerIndex);

  forEach(newData, (player) => {
    if (!includes(existingPlayerIds, player.playerIndex)) {
      const squadPlayer = find(squadData, { playerIndex: player.playerIndex });
      const isCurrentTeamPlayer = squadPlayer?.teamId === teamId;
      if (!isCurrentTeamPlayer) return;
      const updatedMarketCodeDetails = getUpdatedMarketCodeDetails(updatedMarketsByPlayerIndex, player.playerIndex, player.marketCodeDetails);
      const isSuspended = updatedMarketCodeDetails.length
        && !filter(updatedMarketCodeDetails, (market) => !market.suspended)?.length;
      const updatedPlayerPositionIndex = updatedIndexes[player.playerIndex];

      newPlayersToAdd.push({
        ...player,
        playerName: squadPlayer?.personalInfo?.name,
        marketCodeDetails: updatedMarketCodeDetails,
        isSuspended,
        playerPositionIndex: updatedPlayerPositionIndex,
      });
    }
  });
  return newPlayersToAdd;
};

export const updatePlayerPropsData = (existingData, newData, squadData) => {
  const dataToUpdate = cloneDeep(existingData);
  const updatedPlayerParams = cloneDeep(newData.playerParams);
  const updatedMarkets = cloneDeep(newData.playersMarketContext);
  const updatedPlayerPositionIndexes = cloneDeep(newData.playerPositionIndexes);
  dataToUpdate.teamA = updatePlayerPropsDataByTeam(dataToUpdate.teamA, updatedPlayerParams, updatedMarkets, updatedPlayerPositionIndexes);
  dataToUpdate.teamB = updatePlayerPropsDataByTeam(dataToUpdate.teamB, updatedPlayerParams, updatedMarkets, updatedPlayerPositionIndexes);

  const teamANewPlayersToAdd = addNewPlayerDataParamsManager(dataToUpdate.teamA, dataToUpdate?.teamAId, updatedPlayerParams, squadData, updatedMarkets, updatedPlayerPositionIndexes);
  const teamBNewPlayersToAdd = addNewPlayerDataParamsManager(dataToUpdate.teamB, dataToUpdate?.teamBId, updatedPlayerParams, squadData, updatedMarkets, updatedPlayerPositionIndexes);

  return {
    teamA: [
      ...dataToUpdate.teamA,
      ...teamANewPlayersToAdd,
    ],
    teamB: [
      ...dataToUpdate.teamB,
      ...teamBNewPlayersToAdd,
    ],
  };
};

const mapPlayerSetupInferMarketDataByTeam = (eventData, teamPlayersData) => map(teamPlayersData,
  (player) => ({
    playerIndex: player.playerIndex,
    markets: map(player.markets, (market) => ({
      sportId: eventData.sportId,
      eventId: eventData.eventId,
      marketType: {
        marketCode: market.marketCode,
        params: market.params,
      },
      selections: market.selections,
      marketSummary: market.marketSummary,
    })),
  }));

export const mapPlayerSetupInferMarketData = (eventData, playerSetupTableData) => {
  let marketData = [];
  const teamAPlayerMarkets = map(playerSetupTableData.teamA, (player) => ({
    playerIndex: player.playerIndex,
    markets: player.marketCodeDetails,
  }));
  const teamBPlayerMarkets = map(playerSetupTableData.teamB, (player) => ({
    playerIndex: player.playerIndex,
    markets: player.marketCodeDetails,
  }));
  marketData = [
    ...mapPlayerSetupInferMarketDataByTeam(eventData, teamAPlayerMarkets),
    ...mapPlayerSetupInferMarketDataByTeam(eventData, teamBPlayerMarkets),
  ];
  return marketData;
};

export const mergePlayerParamsOnDemand = (playerSetupData, playerSetupTableData, updatedParams) => {
  const { isUSAView } = playerSetupData;
  const { homeTeam, awayTeam } = updatedParams;
  const existingData = cloneDeep(playerSetupTableData);

  const updatedData = {
    teamA: isUSAView ? awayTeam : homeTeam,
    teamB: isUSAView ? homeTeam : awayTeam,
  };
  const teamAData = map(updatedData.teamA?.playersParams, (player) => ({
    ...player?.playerParams,
    lineup: player?.lineup,
    voided: player?.voided,
    marketCodeDetails: player?.marketCodeDetails,
  }));
  const teamBData = map(updatedData.teamB?.playersParams, (player) => ({
    ...player?.playerParams,
    lineup: player?.lineup,
    voided: player?.voided,
    marketCodeDetails: player?.marketCodeDetails,
  }));
  const mergedData = {};

  // Update teamA data
  mergedData.teamA = map(existingData.teamA, (player) => ({
    ...find(teamAData, (updatedPlayer) => updatedPlayer.playerId === player.playerId),
    playerName: player.playerName,
    playerLocked: player.playerLocked,
    playerSet: player.playerSet,
    isSuspended: player.isSuspended,
    marketCodeDetails: player.marketCodeDetails,
    playerPositionIndex: player.playerPositionIndex,
  }));

  // Update teamB data
  mergedData.teamB = map(existingData.teamB, (player) => ({
    ...find(teamBData, (updatedPlayer) => updatedPlayer.playerId === player.playerId),
    playerName: player.playerName,
    playerLocked: player.playerLocked,
    playerSet: player.playerSet,
    isSuspended: player.isSuspended,
    marketCodeDetails: player.marketCodeDetails,
    playerPositionIndex: player.playerPositionIndex,
  }));

  return mergedData;
};

export const extractUpdatedPlayerSetupInferData = (initialData, newData) => {
  const updatedData = {};
  updatedData.teamA = filter(newData.teamA,
    (player) => !isEqual(
      find(initialData.teamA, { playerId: player.playerId }),
      player,
    ));
  updatedData.teamB = filter(newData.teamB,
    (player) => !isEqual(
      find(initialData.teamB, { playerId: player.playerId }),
      player,
    ));
  return updatedData;
};

// Trading UI

const updatePlayerPropsDataByTeamTradingUI = (teamData, updatedPlayerProps, updatedMarketsByPlayerIndex, updatedIndexes) => reduce(
  teamData,
  (allPlayers, player) => {
    const playerToUpdate = cloneDeep(player);

    const updatedPlayer = find(updatedPlayerProps, { playerIndex: player.playerIndex });
    const updatedParams = updatedPlayer || {};
    const updatedMarketCodeDetails = getUpdatedMarketCodeDetails(updatedMarketsByPlayerIndex, updatedPlayer.playerIndex, playerToUpdate.marketCodeDetails);
    const isSuspended = updatedMarketCodeDetails.length
        && !filter(updatedMarketCodeDetails, (market) => !market.suspended)?.length;
    const updatedPlayerPositionIndex = updatedIndexes[player.playerIndex];

    return [
      ...allPlayers,
      {
        ...playerToUpdate,
        ...updatedParams,
        marketCodeDetails: updatedMarketCodeDetails,
        isSuspended,
        playerPositionIndex: updatedPlayerPositionIndex,
      },
    ];
  },
  [],
);

const addNewPlayerDataTradingUI = (existingData, newData, squadData, updatedMarketsByPlayerIndex, updatedIndexes) => {
  const newTeamAPlayers = [];
  const newTeamBPlayers = [];
  const existingPlayerIds = [
    ...map(existingData.teamA?.playerProps, (player) => player.playerIndex),
    ...map(existingData.teamB?.playerProps, (player) => player.playerIndex),
  ];

  forEach(newData, (player) => {
    if (!includes(existingPlayerIds, player.playerIndex)) {
      const isTeamAPlayer = find(squadData, { playerIndex: player.playerIndex })?.teamId === existingData.teamA?.teamId;
      const updatedMarketCodeDetails = getUpdatedMarketCodeDetails(updatedMarketsByPlayerIndex, player.playerIndex, player.marketCodeDetails);
      const isSuspended = updatedMarketCodeDetails.length
        && !filter(updatedMarketCodeDetails, (market) => !market.suspended)?.length;
      const updatedPlayerPositionIndex = updatedIndexes[player.playerIndex];
      if (isTeamAPlayer) {
        newTeamAPlayers.push({
          ...player,
          playerName: find(squadData, { playerIndex: player.playerIndex })?.personalInfo?.name,
          marketCodeDetails: updatedMarketCodeDetails,
          isSuspended,
          playerPositionIndex: updatedPlayerPositionIndex,
        });
      } else {
        newTeamBPlayers.push({
          ...player,
          playerName: find(squadData, { playerIndex: player.playerIndex })?.personalInfo?.name,
          marketCodeDetails: updatedMarketCodeDetails,
          isSuspended,
          playerPositionIndex: updatedPlayerPositionIndex,
        });
      }
    }
  });
  return {
    newTeamAPlayers,
    newTeamBPlayers,
  };
};

export const updatePlayerPropsDataTradingUI = (existingData, newData, squadData) => {
  const dataToUpdate = cloneDeep(existingData);
  const updatedPlayerParams = cloneDeep(newData.playerParams);
  const updatedMarketsByPlayerIndex = cloneDeep(newData.playersMarketContext);
  const updatedPlayerPositionIndexes = cloneDeep(newData.playerPositionIndexes);

  dataToUpdate.teamA.playerProps = updatePlayerPropsDataByTeamTradingUI(dataToUpdate.teamA.playerProps, updatedPlayerParams, updatedMarketsByPlayerIndex, updatedPlayerPositionIndexes);
  dataToUpdate.teamB.playerProps = updatePlayerPropsDataByTeamTradingUI(dataToUpdate.teamB.playerProps, updatedPlayerParams, updatedMarketsByPlayerIndex, updatedPlayerPositionIndexes);

  // Adding newly added players
  const { newTeamAPlayers, newTeamBPlayers } = addNewPlayerDataTradingUI(existingData, updatedPlayerParams, squadData, updatedMarketsByPlayerIndex, updatedPlayerPositionIndexes);
  dataToUpdate.teamA.playerProps = [
    ...dataToUpdate.teamA.playerProps,
    ...newTeamAPlayers,
  ];

  dataToUpdate.teamB.playerProps = [
    ...dataToUpdate.teamB.playerProps,
    ...newTeamBPlayers,
  ];

  return dataToUpdate;
};
const mapTeamLineupPlayerParams = (players) => map(players, (player) => ({
  playerParams: {
    playerId: player.playerId,
    playerIndex: player.playerIndex,
    sport: player.sport,
    pitcherParams: player.pitcherParams,
    hitterParams: player.hitterParams,
  },
  marketCodeDetails: map(player.marketCodeDetails, (market) => ({ marketCode: market.marketCode })),
  lineup: player.lineup,
  playerPositionIndex: player.playerPositionIndex,
}));
export const mapPlayerSetupBaseballTeamLineupData = (data) => {
  const pitchers = filter(data, (player) => player.isPitcher);
  const hitters = filter(data, (player) => player.isHitter);
  return {
    pitchers: mapTeamLineupPlayerParams(pitchers),
    hitters: mapTeamLineupPlayerParams(hitters),
  };
};
